import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // 백엔드에 로그아웃 요청
      await axios.post(`${apiUrl}/auth/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      // 로컬 스토리지에서 사용자 정보 제거
      localStorage.removeItem('email');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('id');
      localStorage.removeItem('isLoggedIn');

      // 홈 페이지로 리디렉션
      navigate('/');
    } catch (error) {
      console.error('로그아웃 실패:', error);
    }
  };

  handleLogout();
};

export default Logout;