import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CodeBlock from '../CodeBlock';
import axios from 'axios';
import CodeEditor from '@monaco-editor/react';
import './ProblemSolving.css';

const apiUrl = process.env.REACT_APP_API_URL;

const ProblemSolving = () => {
  const { problemId } = useParams();
  const [problem, setProblem] = useState(null);
  const [language, setLanguage] = useState('java');
  const [code, setCode] = useState('');
  const [testcases, setTestcases] = useState([]);
  const [executionResult, setExecutionResult] = useState(null);
  const [submissionResult, setSubmissionResult] = useState(null);
  const [hintResult, setHintResult] = useState("잠시만 기다려주세요. 여기에 힌트가 표시됩니다.");
  const [feedbackResult, setFeedbackResult] = useState(null);
  const [isExcutionLoading, setIsExcutionLoading] = useState(false);
  const [isHintLoading, setIsHintLoading] = useState(false);

  const token = localStorage.getItem("token");
  const memberId = localStorage.getItem("id");
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const algorithmTypes = [
    { key: 'HASH', label: '해시' },
    { key: 'STACK', label: '스택' },
    { key: 'QUEUE', label: '큐' },
    { key: 'DEQUEUE', label: '덱' },
    { key: 'DP', label: 'DP' },
    { key: 'GREEDY', label: '그리디' },
    { key: 'TREE', label: '트리' },
    { key: 'DFS', label: 'DFS' },
    { key: 'BFS', label: 'BFS' },
    { key: 'BINARYSEARCH', label: '이분탐색' },
    { key: 'BRUTEFORCE', label: '완전탐색' },
    { key: 'IMPLEMENTATION', label: '구현' }
  ];
  
  const getLabelByKey = (key) => {
    const algorithm = algorithmTypes.find(type => type.key === key);
    return algorithm ? algorithm.label : '';
  };

  useEffect(() => {
    fetchProblem();
    document.body.style.backgroundColor = '#212121';

    // 컴포넌트가 언마운트될 때 원래 색상으로 되돌림
    return () => {
      document.body.style.backgroundColor = '#fafafa';

    };
  }, [problemId]);

  const fetchProblem = async () => {
    try {
      var response = null;
      if (!isLoggedIn) {
        response = await axios.get(`${apiUrl}/problem/${problemId}`);

      } else {
        response = await axios.get(`${apiUrl}/problem/${problemId}/${memberId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      setProblem(response.data.data);
      setCode(response.data.data.template[language]);
      setTestcases(response.data.data.testcases);

    } catch (error) {
      console.error('Error fetching problem:', error);
    }
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    setCode(problem.template[newLanguage]);
  };

  const handleCodeChange = (value) => {
    setCode(value);
  };

  const handleHintRequest = async () => {
    setIsHintLoading(true);
    const hintDiv = document.getElementById('hint');
    if (hintDiv) {
      setHintResult("잠시만 기다려주세요. 여기에 힌트가 표시됩니다.");
      hintDiv.style.display = 'block';
    }

    try {
      const response = await axios.post(`${apiUrl}/problem/hint`, {
        memberId,
        description: problem.description,
        constraints: problem.constraints,
        code
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setHintResult(response.data.data.output.content);

    } catch (error) {
      console.error('Error fetching hint:', error);
    } finally {
      setIsHintLoading(false);
    }
  };

  const handleFeedbackRequest = async () => {
    closeSubmissionWindow();

    const feedbackWindowDiv = document.getElementById('feedback-window');
    if (feedbackWindowDiv) {
      feedbackWindowDiv.style.display = 'flex';
    }

    try {
      const response = await axios.post(`${apiUrl}/problem/feedback`, {
        memberId,
        description: problem.description,
        constraints: problem.constraints,
        code
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFeedbackResult(response.data.data.output.content);

    } catch (error) {
      console.error('Error fetching hint:', error);
    } finally {

    }
  };

  const validateTestCase = (testCase) => {
    for (let i = 0; i < testCase.length - 1; i++) {
      try {
        JSON.parse(testCase[i]);
      } catch (error) {
        //console.error(`Syntax error in input ${i + 1}: ${testCase[i]}`);
        return false;
      }
    }
    return true;
  };

  const convertTestCases = (rawData) => {
    const testCaseData = rawData.slice(1);

    return testCaseData.map(testCase => {
      const output = testCase[testCase.length - 1];
      const input = testCase.slice(0, -1);

      return {
        input: input.map(item => {
          return item.replace(/'/g, '"');
        }),
        output: output
      };
    });
  };

  const handleExecution = async () => {
    try {
      setIsExcutionLoading(true);
      const customTestcases = convertTestCases(testcases);
      const response = await axios.post(`${apiUrl}/problem/execute`, {
        memberId,
        problemId,
        code,
        language: language.toUpperCase(),
        testcases: customTestcases,
        submissionType: "EXECUTE"
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setExecutionResult(response.data.data);

    } catch (error) {
      console.error('Error executing code:', error);
    } finally {
      setIsExcutionLoading(false);
    }
  };

  const handleSubmission = async () => {
    try {
      const submissionWindowDiv = document.getElementById('submission-window');
      if (submissionWindowDiv) {
        submissionWindowDiv.style.display = 'flex';
      }

      const response = await axios.post(`${apiUrl}/problem/submit`, {
        memberId,
        problemId,
        code,
        language: language.toUpperCase(),
        submissionType: "SUBMIT"
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubmissionResult(response.data.data);
    } catch (error) {
      console.error('Error submitting solution:', error);
    }
  };

  const tableRef = useRef(null);
  const inputRefs = useRef([]);
  const [isTableReady, setIsTableReady] = useState(false);
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    if (tableRef.current && tableRef.current.rows.length > 0) {
      setIsTableReady(true);
    }
  }, [problem]);

  const addTestCase = () => {
    const newTestCase = inputRefs.current.map(ref => ref.value);

    // 빈 칸 체크
    const emptyFieldIndex = newTestCase.findIndex(value => value.trim() === '');
    if (emptyFieldIndex !== -1) {
      alert('모든 입력 필드를 채워주세요.');
      inputRefs.current[emptyFieldIndex].focus();
      return;
    }
    if (!validateTestCase(newTestCase)) {
      alert('테스트 케이스가 올바르지 않습니다.');
      return;
    }

    setTestcases(prevTestcases => [...prevTestcases, newTestCase]);

    setTableKey(prevKey => prevKey + 1);

    // 입력 필드 초기화
    inputRefs.current.forEach(ref => ref.value = '');

    // 첫 번째 입력 필드로 포커스 이동
    if (inputRefs.current.length > 0) {
      inputRefs.current[0].focus();
    }
  };

  const TestcaseTable = () => {
    return (
      <div>
        <table id="TestcaseTable" ref={tableRef} key={tableKey}>
          <thead>
            <tr>
              {testcases[0].map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {testcases.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
            <tr>
              {problem.testcases[0].map((_, index) => (
                <td>
                  <input
                    key={index}
                    type="text"
                    ref={el => inputRefs.current[index] = el}
                    placeholder={`${problem.testcases[0][index]}`}
                  />
                </td>
              ))}

            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const ExcutionResults = ({ data }) => {
    if (!data) {
      return "실행 결과가 여기에 표시됩니다."; // data가 null이면 아무것도 렌더링하지 않음
    }

    const passCount = data.results.filter(result => result.evaluation === "Pass").length;
    const totalCount = data.results.length;

    return (
      <div className="excution-result">
        <h2>테스트 결과</h2>
        <p className="h4" style={{ color: passCount === totalCount ? 'var(--dodger-blue)' : 'var(--red-orange)' }}>
          {totalCount}개 중 {passCount}개 성공
        </p>
        <div className="frame-33-1 frame-33-7"></div>
        {data.results.map((result, index) => (
          <div key={index} style={{ marginBottom: '15px' }}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td colspan="2">테스트 {result.order}</td>
                </tr>
                <tr>
                  <td className="title">기댓값</td>
                  <td className="content">{result.expected}</td>
                </tr>
                <tr>
                  <td className="title">결과값</td>
                  <td className="content" style={{ color: result.evaluation === "Pass" ? 'var(--dodger-blue)' : 'var(--red-orange)' }}>
                    {result.actual} ({result.evaluation === "Pass" ? '성공' : '실패'})
                  </td>
                </tr>
                <tr>
                  <td className="title">출력</td>
                  <td className="content">{result.user_output || '출력 없음'}</td>
                </tr>
                <tr>
                  <td className="title">메모리 사용량</td>
                  <td className="content">{result.memory_usage} MB</td>
                </tr>
                <tr>
                  <td className="title">실행시간</td>
                  <td className="content">{result.time_usage} ms</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    );
  };

  const closeHint = () => {
    const hintDiv = document.getElementById('hint');
    if (hintDiv) {
      hintDiv.style.display = 'none';
    }
  }

  const closeSubmissionWindow = () => {
    const submissionWindowDiv = document.getElementById('submission-window');
    if (submissionWindowDiv) {
      submissionWindowDiv.style.display = 'none';
    }
    setSubmissionResult(null);
  }

  const closeFeedbackWindow = () => {
    const feedbackWindowDiv = document.getElementById('feedback-window');
    if (feedbackWindowDiv) {
      feedbackWindowDiv.style.display = 'none';
    }
    setFeedbackResult(null);
  }

  const codeReset = () => {
    const languageSelect = document.getElementById('languageSelect');
    const seletedLanguage = languageSelect.value;
    setCode(problem.template[seletedLanguage]);
  }

  const excutionReset = () => {
    setExecutionResult(null);
  }

  const getEvaluationStyle = (evaluation) => {
    return {
      color: evaluation === 'Pass' ? '#2196f3' : '#f44336',
    };
  };

  if (!problem) {
    return <div id="loading"></div>;
  }

  return (
    <div className="problem-solving-body">
      <div className="problem-solving-page">
        <div className="left-panel">
          <div className="frame-333">
            <div className="frame-33">
              <div className="icon"></div>
              <p className="sql-select s1normal">문제 &gt; {getLabelByKey(problem.category)}</p>
            </div>
            <Link to="/problem/list">
              <div className="solving-button">
                <div className="icon-1 icon-4"></div>
                <div className="solving-button-4 s1normal">문제 목록</div>
              </div>
            </Link>
          </div>
          <div className="frame-3335"></div>
          <div className="frame-3347">
            <div className="frame-3351">
              <div className="frame-3350">
                <div className="frame-334"><div className="lv5 s2bold">Lv.{problem.level}</div></div>
                <div className="frame-334"><div className="text-162 s2bold">{getLabelByKey(problem.category)}</div></div>
              </div>
              <h2 className="text-163 h3">{problem.title}</h2>
            </div>
            <div className="frame-335"></div>
            <div className="frame-33-1 frame-33-4">
              <h3 className="text-1 h6">문제 설명</h3>
              <p dangerouslySetInnerHTML={{ __html: problem.description }} className="text-165 pnormal" />
            </div>
            <div className="frame-335"></div>
            <div className="frame-33-1 frame-33-4">
              <h3 className="text-1 h6">제약사항</h3>
              <p dangerouslySetInnerHTML={{ __html: problem.constraints }} className="text-165 pnormal" />
            </div>
            <div className="frame-335"></div>
            <div className="frame-33-1 frame-33-4 h6">
              <h3 className="text-1 h6">테스트 케이스</h3>
              <div className="test-cases">
                <TestcaseTable />
                <button className="h6" onClick={addTestCase}>테스트 케이스 추가하기</button>
              </div>
            </div>
          </div>
        </div>
        <div className="right-panel">
          <div className="frame-333">
            <div className="frame-3345">
              <div className="icon-2 icon-4"></div>
              <div className="text-174 s1normal">코드</div>
            </div>
            <div className="frame-334-1">
              <div className="language-selector">
                <select value={language} id="languageSelect" onChange={handleLanguageChange}>
                  <option value="java">Java</option>
                  <option value="python">Python 3</option>
                </select>
              </div>
              <article className="solving-button" onClick={codeReset}><div className="solving-button-2 s1normal">초기화</div></article>
              {isLoggedIn ? (
                <button className="solving-button-3 solving-button-1 h6" onClick={handleHintRequest} disabled={isHintLoading}>
                  <img className="mdicreation" src="/img/mdi-creation-1.svg" alt="mdi:creation" />
                  {isHintLoading ? '실행 중...' : 'AI 힌트'}
                </button>
              ) : (
                <Link to="/login"><button className="solving-button-3 solving-button-1 h6"><img className="mdicreation" src="/img/mdi-creation-1.svg" alt="mdi:creation" /> AI 힌트</button></Link>
              )}
            </div>
          </div>
          <div className="frame-3335"></div>
          <CodeEditor
            height="100%"
            width="100%"
            language={language}
            value={code}
            theme="vs-dark"
            onChange={handleCodeChange}
            options={{
              fontSize: 16,
              automaticLayout: true
            }}
          />
          <div className="hint" id="hint">
            <div className="frame-3395">
              <div className="frame-3396">
                <img className="mdicreation-1" src="/img/mdi-creation-2.svg" alt="mdi:creation" />
                <h6 className="ai h6">AI 힌트</h6>
              </div>
              <div className="icon-3 icon-5" onClick={closeHint} />
            </div>
            <p id="hintText" className="text_label-1 s1normal">
              <CodeBlock html={hintResult} language={language} />
            </p>
          </div>
          <div className="frame-3332">
            <div className="frame-3336">
              <div className="frame-3345">
                <div className="icon-3 icon-4"></div>
                <div className="text-176 s1normal">실행 결과</div>
              </div>
              <div className="frame-334-1">
                <article className="solving-button" onClick={excutionReset}><div className="solving-button-2 s1normal">초기화</div></article>
                {isLoggedIn ? (
                  <div className="action-buttons">
                    <button className="solving-button-6 solving-button-7 h6" onClick={handleExecution} disabled={isExcutionLoading}>
                      <img className="tablerplayer-play" src="/img/tabler-player-play-1.svg" alt="tabler:player-play" />
                      {isExcutionLoading ? '실행 중...' : '코드 실행'}
                    </button>
                    <button className="solving-button-3 solving-button-1 h6" onClick={handleSubmission}><img className="tablersend" src="/img/tabler-send-1.svg" alt="tabler:send" />채점하기</button>
                  </div>
                ) : (
                  <div className="action-buttons">
                    <Link to="/login"><button className="solving-button-6 solving-button-7 h6"><img className="tablerplayer-play" src="/img/tabler-player-play-1.svg" alt="tabler:player-play" />코드 실행</button></Link>
                    <Link to="/login"><button className="solving-button-3 solving-button-1 h6"><img className="tablersend" src="/img/tabler-send-1.svg" alt="tabler:send" />채점하기</button></Link>
                  </div>
                )}
              </div>
            </div>
            <div className="frame-3335"></div>
            <div className="frame-3370">
              <div className="frame-3369">
                <div className="text-177 s1normal">
                  <div>
                    <ExcutionResults data={executionResult} />
                  </div>
                  {/* {executionResult && (
                    <div className="execution-result">
                      <h3>Execution Result</h3>
                      <pre>{JSON.stringify(executionResult, null, 2)}</pre>
                    </div>
                  )} */}
                  {/* {submissionResult && (
                    <div className="submission-result">
                      <h3>Submission Result</h3>
                      <pre>{JSON.stringify(submissionResult, null, 2)}</pre>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-3400" id="submission-window">
        {submissionResult ? (
          submissionResult.all_pass ? (
            <div className="frame-3401">
              <div className="frame-3402">
                <h4 className="text-113 h4">채점하기</h4>
                <div className="icon" onClick={closeSubmissionWindow}></div>
              </div>
              <div className="frame-340">
                <div className="fluent-3dclapping-hands"></div>
                <h3 className="text-114 h3">정답입니다!</h3>
              </div>

              <div className="frame-340">
                <div className="text-115 s1normal">테스트 결과</div>
                <div>

                  <table className="submit-table pnormal" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                      {submissionResult.results.map((result) => (
                        <tr key={result.order}>
                          <td className="test-order">테스트 {result.order}</td>
                          <td className="test-result" style={getEvaluationStyle(result.evaluation)}>{result.evaluation}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              </div>

              <div className="frame-3405">
                <article className="button-2" onClick={handleFeedbackRequest}>
                  <img className="mdicreation" src="/img/mdi-creation-4.svg" alt="mdi:creation" />
                  <h6 className="button-3 h6">AI 피드백 받아보기</h6>
                </article>
              </div>
            </div>
          ) : (
            <div className="frame-3401">
              <div className="frame-3402">
                <h4 className="text-113 h4">채점하기</h4>
                <div className="icon" onClick={closeSubmissionWindow}></div>
              </div>
              <div className="frame-340">
                <div className="fluent-3dcrying-face"></div>
                <h3 className="text-114 h3">틀렸습니다. 코드를 다시 확인해주세요.</h3>
              </div>

              <div className="frame-340">
                <div className="text-115 s1normal">테스트 결과</div>
                <div>

                  <table className="submit-table pnormal" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                      {submissionResult.results.map((result) => (
                        <tr key={result.order}>
                          <td className="test-order">테스트 {result.order}</td>
                          <td className="test-result" style={getEvaluationStyle(result.evaluation)}>{result.evaluation}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              </div>

              <div className="frame-3405">
                <article className="button-2" onClick={handleFeedbackRequest}>
                  <img className="mdicreation" src="/img/mdi-creation-4.svg" alt="mdi:creation" />
                  <h6 className="button-3 h6">AI 피드백 받아보기</h6>
                </article>
              </div>
            </div>
          )) : (
          <div id="loading"></div>
        )}
      </div>
      <div className="frame-3400" id="feedback-window">
        {feedbackResult ? (
          <div className="frame-3401">
            <div className="frame-3402">
              <h4 className="text-113 h4">AI 피드백</h4>
              <div className="icon" onClick={closeFeedbackWindow}></div>
            </div>
            <div className="frame-340">
              <CodeBlock html={feedbackResult} language={language} />
            </div>
            <div className="frame-3405">
              <article className="button">
                <img className="tablerbookmark" src="/img/tabler-bookmark-1.svg" alt="tabler:bookmark" />
                <h6 className="button-1 h6">AI 피드백 저장하기</h6>
              </article>
            </div>
          </div>
        ) : (
          <div id="loading"></div>
        )}
      </div>
    </div>
  );
};

export default ProblemSolving;