import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './ProblemList.css';

const apiUrl = process.env.REACT_APP_API_URL;

const ProblemList = () => {
  const token = localStorage.getItem("token");
  const memberId = localStorage.getItem("id");
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const [problems, setProblems] = useState(null);
  const [filters, setFilters] = useState({
    levels: [],
    categories: [],
    title: '',
  });

  useEffect(() => {
    setProblems(null);
    fetchProblems();
  }, [filters]);

  const fetchProblems = async () => {
    try {
      if (isLoggedIn) {
        const response = await axios.get(`${apiUrl}/problem/filter-for-member/${memberId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            levels: filters.levels,
            categories: filters.categories,
            title: filters.title
          }
        });
        setProblems(response.data.data);
      } else {
        const response = await axios.get(`${apiUrl}/problem/filter`, {
          params: {
            levels: filters.levels,
            categories: filters.categories,
            title: filters.title
          }
        });
        setProblems(response.data.data);
      }

    } catch (error) {
      console.error('문제 목록을 가져오는 데 실패했습니다:', error);
    }
  };

  const handleLevelChange = (level) => {
    setFilters(prev => ({
      ...prev,
      levels: prev.levels.includes(level)
        ? prev.levels.filter(l => l !== level)
        : [...prev.levels, level]
    }));
  };

  const handleCategoryChange = (category) => {
    setFilters(prev => ({
      ...prev,
      categories: prev.categories.includes(category)
        ? prev.categories.filter(a => a !== category)
        : [...prev.categories, category]
    }));
  };

  const handleTitleChange = (e) => {
    setFilters(prev => ({ ...prev, title: e.target.value }));
  };

  const algorithmTypes = [
    { key: 'HASH', label: '해시' },
    { key: 'STACK', label: '스택' },
    { key: 'QUEUE', label: '큐' },
    { key: 'DEQUEUE', label: '덱' },
    { key: 'DP', label: 'DP' },
    { key: 'GREEDY', label: '그리디' },
    { key: 'TREE', label: '트리' },
    { key: 'DFS', label: 'DFS' },
    { key: 'BFS', label: 'BFS' },
    { key: 'BINARYSEARCH', label: '이분탐색' },
    { key: 'BRUTEFORCE', label: '완전탐색' },
    { key: 'IMPLEMENTATION', label: '구현' }
  ];

  const getLabelByKey = (key) => {
    const algorithm = algorithmTypes.find(type => type.key === key);
    return algorithm ? algorithm.label : '';
  };

  const [hoveredItem, setHoveredItem] = useState(null);

  return (
    <div className="problem-list">
      <div className="search">
        <input
          className="value s1normal"
          type="text"
          value={filters.title}
          onChange={handleTitleChange}
          placeholder="문제 검색"
        />
        <div className="icon"></div>
      </div>
      <div className="frame-3371">
        <div className="frame-3385">
          <h5 className="text-1-1 h5">필터</h5>
          <div className="frame-339">
            <h3 className="text s2bold">알고리즘 분류</h3>
            {algorithmTypes.map(algo => (
              <label key={algo.key}>
                <input
                  type="checkbox"
                  checked={filters.categories.includes(algo.key)}
                  onChange={() => handleCategoryChange(algo.key)}
                />
                {algo.label}
              </label>
            ))}
          </div>
          <div className="frame-339">
            <h3 className="text s2bold">난이도</h3>
            {[1, 2, 3, 4, 5].map(level => (
              <label key={level}>
                <input
                  type="checkbox"
                  checked={filters.levels.includes(level)}
                  onChange={() => handleLevelChange(level)}
                />
                레벨 {level}
              </label>
            ))}
          </div>
        </div>
        <div className="frame-3381">
          {problems ? (
            <ul className="frame-3380">
              <div className="frame-3373 s2normal">
                <div className="text-4-1">상태</div>
                <div className="text-5">제목</div>
                <div className="text-1">난이도</div>
                <div className="text-1">분류</div>
                <div className="text-1">제출</div>
                <div className="text-1">정답 비율</div>
              </div>
              {problems.map(problem => (
                <li
                  onMouseEnter={() => setHoveredItem(problem.id)}
                  onMouseLeave={() => setHoveredItem(null)}
                  className="frame-33" key={problem.id}>
                  <div className="frame-33-1">
                    {problem.solved ?
                      (<div className="icon-solved"></div>) : (<div className={`icon-3 icon-1 ${hoveredItem === problem.id ? 'visible' : ''}`}></div>)
                    }
                  </div>
                  <div className="text-2 pnormal"><Link to={`/problem/solving/${problem.id}`}>{problem.title}</Link></div>
                  <div className="frame-3378">
                    <div className={`lv${problem.level} s2bold`}>Lv.{problem.level}</div>
                  </div>
                  <div className="text-11 pnormal">{getLabelByKey(problem.category)}</div>
                  <div className="text-12 pnormal">{problem.submitCount}</div>
                  <div className="percent pnormal">{Math.round(problem.correctRate)}%</div>
                </li>
              ))}
            </ul>
          )
            : (
              <div style={{ height: '774px', width: '100%', display: 'flex' }}>
                <div className="lds-dual-ring"></div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ProblemList;