import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({ html, language }) => {
  // HTML 문자열에서 코드 블록 추출
  const codeRegex = /```([\s\S]*?)```/g;
  const parts = html.split(codeRegex);

  return (
    <div>
      {parts.map((part, index) => {
        if (index % 2 === 0) {
          // 일반 텍스트
          return <div key={index} dangerouslySetInnerHTML={
            {
              __html: part.replace(/^###\s*(.*?)$/gm, '<h2>$1</h2>').replace(/^(?!<h2>)(.+)$/gm, '<p>$1</p>').replace(/\*\*(.+?)\*\*/g, '<b>$1</b>')
            }
          } />;
        } else {
          // 코드 블록
          return (
            <SyntaxHighlighter
              key={index}
              language={language}
              style={vscDarkPlus}
            >
              {part.trim()}
            </SyntaxHighlighter>
          );
        }
      })}
    </div>
  );
};

export default CodeBlock;