import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const GoogleCallback = (props) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    let params = new URL(document.URL).searchParams;
    let code = params.get('code');
    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/google/callback?code=${code}`); 
                const { token, id, email, role, deleted } = response.data.data;

                if (deleted) {
                    localStorage.setItem('id', id);
                    setShowConfirmDialog(true);
                } else {
                    completeLogin(email, token, role, id);
                }
            } catch (error) {
                console.log('googleLogin Failed');
                navigate('/');
            }            
        };
        
        fetchData();
    }, [code, navigate]);

    const completeLogin = (email, token, role, id) => {
        localStorage.setItem('email', email);
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        localStorage.setItem('id', id);
        localStorage.setItem('isLoggedIn', true);

        const prevPath = sessionStorage.getItem('prevPath') || '/';
        navigate(prevPath);
    };

    const handleConfirmWithdrawal = async (confirm) => {
        if (confirm) {
            try {
                const response = await axios.post(`${apiUrl}/auth/member-deletion-cancel`, {}, {
                    headers: {
                        'requestId': localStorage.getItem('id')
                    }
                });
                
                completeLogin(response.data.data.email, response.data.data.token, response.data.data.role, response.data.data.id);
                
            } catch (error) {
                console.log('Withdrawal cancellation failed');
            }
        } else {
            navigate('/login');
        }
        setShowConfirmDialog(false);
    };

    if (showConfirmDialog) {
        return (
            <div>
                <p>회원탈퇴를 취소 하시겠습니까?</p>
                <button onClick={() => handleConfirmWithdrawal(true)}>예</button>
                <button onClick={() => handleConfirmWithdrawal(false)}>아니오</button>
            </div>
        );
    }

    return null;
};

export default GoogleCallback;