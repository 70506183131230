import "./Header.css";
import { Link, useLocation } from 'react-router-dom';

const Header = (props) => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const getClassName = (path) => {
    return location.pathname === path ? 'clicked' : 'text-4';
  };

  return (
    <header className="header">
      <div className="frame-3322">
        <div className="frame-3321">
          <div className="frame-3324">
            <Link to="/"><img className="logo" src="/img/logo-2.svg" alt="Logo" /></Link>
            <Link to="/"><img className="logo-1" src="/img/logo-3.svg" alt="Logo" /></Link>
          </div>
          <div className="frame-3320 h6">
            <li className={getClassName('/problem/list')}>
              <Link to="/problem/list">AI코치</Link>
            </li>
            <li className={getClassName('/coding-interview')}>
              <Link to="/coding-interview">코딩 인터뷰</Link>
            </li>
            <li className={getClassName('/recruitment')}>
              <Link to="/recruitment">채용</Link>
            </li>
          </div>
        </div>
        <div className="frame-3319">
          {isLoggedIn ? (
            <Link to="/mypage"><div className="avatar"></div></Link>
          ) : (
            <Link to="/login"
              state={{ from: location.pathname }}>
              <div className="button-3 s2normal">로그인 / 회원가입</div>
            </Link>
          )}
          {isLoggedIn ? (
            <Link to="/logout"><div className="button-3 s2normal">로그아웃</div></Link>
          ) : (
            <p></p>
          )}
          <div className="button"><div className="button-1 s2normal">기업 서비스</div></div>
        </div>
      </div>
    </header>
  );
}

export default Header;