import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Home.css';

const apiUrl = process.env.REACT_APP_API_URL;

function Home() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const token = localStorage.getItem("token");
  const memberId = localStorage.getItem("id");

  const [newProblems, setNewProblems] = useState(null);
  const [mostSubmittedProblems, setMostSubmittedProblems] = useState(null);
  const [lowestCorrectRateProblems, setLowestCorrectRateProblems] = useState(null);
  const [solveCount, setSolveCount] = useState(null);
  const [noteCount, setNoteCount] = useState(null);
  const [solvingProblem, setSolvingProblem] = useState(null);

  const algorithmTypes = [
    { key: 'HASH', label: '해시' },
    { key: 'STACK', label: '스택' },
    { key: 'QUEUE', label: '큐' },
    { key: 'DEQUEUE', label: '덱' },
    { key: 'DP', label: 'DP' },
    { key: 'GREEDY', label: '그리디' },
    { key: 'TREE', label: '트리' },
    { key: 'DFS', label: 'DFS' },
    { key: 'BFS', label: 'BFS' },
    { key: 'BINARYSEARCH', label: '이분탐색' },
    { key: 'BRUTEFORCE', label: '완전탐색' },
    { key: 'IMPLEMENTATION', label: '구현' }
  ];

  const getLabelByKey = (key) => {
    const algorithm = algorithmTypes.find(type => type.key === key);
    return algorithm ? algorithm.label : '';
  };

  useEffect(() => {
    fetchNewProblems();
    fetchMostSubmittedProblems();
    fetchLowestCorrectRateProblems();
    fetchSolveCount();
    fetchNoteCount();
    fetchSolvingProblem();
  }, []);

  const fetchNewProblems = async () => {
    try {
      var response = await axios.get(`${apiUrl}/problem/new-problems`);

      setNewProblems(response.data.data);

    } catch (error) {
      console.error('Error fetching new problems:', error);
    }
  };

  const fetchMostSubmittedProblems = async () => {
    try {
      var response = await axios.get(`${apiUrl}/problem/most-submitted-problems`);

      setMostSubmittedProblems(response.data.data);

    } catch (error) {
      console.error('Error fetching new problems:', error);
    }
  };

  const fetchLowestCorrectRateProblems = async () => {
    try {
      var response = await axios.get(`${apiUrl}/problem/lowest-correct-rate-problems`);

      setLowestCorrectRateProblems(response.data.data);

    } catch (error) {
      console.error('Error fetching new problems:', error);
    }
  };

  const fetchSolveCount = async () => {
    if (isLoggedIn) {
      try {
        var response = await axios.get(`${apiUrl}/problem/solve-count/${memberId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setSolveCount(response.data.data);

      } catch (error) {
        console.error('Error fetching new problems:', error);
      }
    }
  };

  const fetchNoteCount = async () => {
    if (isLoggedIn) {
      try {
        var response = await axios.get(`${apiUrl}/feedback-note/note-count/${memberId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setNoteCount(response.data.data);

      } catch (error) {
        console.error('Error fetching new problems:', error);
      }
    }
  };

  const fetchSolvingProblem = async () => {
    if (isLoggedIn) {
      try {
        var response = await axios.get(`${apiUrl}/problem/solving/${memberId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setSolvingProblem(response.data.data);

      } catch (error) {
        console.error('Error fetching new problems:', error);
      }
    }
  };


  return (
    <div className="home">
      <div className="frame-3418">
        <div className="frame-3420">
          <div className="frame-34">
            <p className="text-83">코딩테스트 합격률 200% 높이는 비밀 무기</p>
            <h1 className="ai-1 pretendard-bold-black-27px">
              <span className="pretendard-bold-black-27px">코드지니 AI 코치와 함께 성장하기</span><span className="span1"> 🚀</span>
            </h1>
          </div>
          <p className="ai-2 s1normal">
            실전 같은 AI 코딩 면접으로 완벽 준비, 맞춤형 기업 추천까지!<br />꿈꾸던 회사로 가는 지름길이 여기 있어요.
          </p>
        </div>
        <img className="image-8" src="img/image-8.png" alt="image 8" />
      </div>
      {isLoggedIn ? (
        <div className="frame-3452">
          <div className="frame-34-1">
            <h6 className="text-2 h6">내가 푼 문제</h6>
            <h2 className="text-26 h2"><Link to="/mypage/solved-problems">{solveCount}개</Link></h2>
          </div>
          <div className="frame-34-1">
            <h6 className="text-2 h6">피드백 노트</h6>
            <h2 className="text-28 h2"><Link to="/mypage/feedback-note">{noteCount}개</Link></h2>
          </div>
          <div className="frame-34-1">
            <h6 className="text-2 h6">푸는 중 문제</h6>
            {solvingProblem ? (solvingProblem.map((problem) => (
            <div className="frame-34-2">
              <div className="text-30 pnormal"><Link to={`/problem/solving/${problem.id}`}>{problem.title}</Link></div>
              <div className="frame-3430">
                <div className={`circle-lv${problem.level}`}><div className="lv-text s2bold">Lv.{problem.level}</div></div>
                <div className="frame-3429"><div className="text-57 s2bold">{getLabelByKey(problem.category)}</div></div>
              </div>
            </div>
            ))) : ("로딩 중...")}
          </div>
        </div>
      ) : ("")}
      <div className="frame-3425">
        <div className="frame-342">
          <h5 className="text-55 h5">새로 나온 문제</h5>
          <div className="frame-343">
            <div className="frame-345"></div>
            {newProblems ? (newProblems.map((problem) => (
              <>
                <div className="frame-343-1 frame-343-3">
                  <div className="text s1normal"><Link to={`/problem/solving/${problem.id}`}>{problem.title}</Link></div>
                  <div className="frame-3430">
                    <div className={`circle-lv${problem.level}`}><div className="lv-text s2bold">Lv.{problem.level}</div></div>
                    <div className="frame-3429"><div className="text-57 s2bold">{getLabelByKey(problem.category)}</div></div>
                  </div>
                </div>
                <div className="frame-345"></div>
              </>
            ))) : ("로딩 중...")}
          </div>
        </div>
        <div className="frame-342">
          <h5 className="text-55 h5">이번 주 많이 푼 문제</h5>
          <div className="frame-343">
            <div className="frame-345"></div>
            {mostSubmittedProblems ? (mostSubmittedProblems.map((problem) => (
              <>
                <div className="frame-343-1 frame-343-3">
                  <div className="text s1normal"><Link to={`/problem/solving/${problem.id}`}>{problem.title}</Link></div>
                  <div className="frame-3430">
                    <div className={`circle-lv${problem.level}`}><div className="lv-text s2bold">Lv.{problem.level}</div></div>
                    <div className="frame-3429"><div className="text-57 s2bold">{getLabelByKey(problem.category)}</div></div>
                  </div>
                </div>
                <div className="frame-345"></div>
              </>
            ))) : ("로딩 중...")}
          </div>
        </div>
        <div className="frame-342">
          <h5 className="text-55 h5">정답률 낮은 문제</h5>
          <div className="frame-343">
            <div className="frame-345"></div>
            {lowestCorrectRateProblems ? (lowestCorrectRateProblems.map((problem) => (
              <>
                <div className="frame-343-1 frame-343-3">
                  <div className="text s1normal"><Link to={`/problem/solving/${problem.id}`}>{problem.title}</Link></div>
                  <div className="frame-3430">
                    <div className={`circle-lv${problem.level}`}><div className="lv-text s2bold">Lv.{problem.level}</div></div>
                    <div className="frame-3429"><div className="text-57 s2bold">{getLabelByKey(problem.category)}</div></div>
                  </div>
                </div>
                <div className="frame-345"></div>
              </>
            ))) : ("로딩 중...")}
          </div>
        </div>
      </div>
      <div className="frame-3442">
        <h5 className="text-76 h5">지금 채용 중인 포지션</h5>
        <div className="frame-3441">
          <div className="frame-343-2 frame-343-3">
            <div className="frame-343">
              <img className="image-9" src="img/image-9@2x.png" alt="image 9" />
              <div className="frame-34">
                <h5 className="django-1 django-3 h5">[포카마켓] 백엔드 Django 개발자 (1년 이상)</h5>
                <div className="text-77 s1normal">인플루디오</div>
              </div>
            </div>
            <div className="frame-3439">
              <p className="text-1 s1normal">서울 마포구 ∙ 경력 1-3년 ∙ 상시</p>
              <div className="frame-3437">
                <div className="frame-34-1"><div className="git s2bold">Git</div></div>
                <div className="frame-34-1"><div className="git s2bold">Django</div></div>
                <div className="frame-34-1"><div className="git s2bold">Python</div></div>
              </div>
            </div>
          </div>
          <div className="frame-343-2 frame-343-3">
            <div className="frame-343">
              <img className="image-9" src="img/image-9-1@2x.png" alt="image 9" />
              <div className="frame-34">
                <h5 className="django-1 django-3 h5">프론트엔드 개발자</h5>
                <div className="text-77 s1normal">고퀄(GOQUAL)</div>
              </div>
            </div>
            <div className="frame-3439">
              <p className="text-1 s1normal">서울 금천구 ∙ 경력 1-3년 ∙ 상시</p>
              <div className="frame-3437">
                <div className="frame-34-1"><div className="git s2bold">JavaScript</div></div>
                <div className="frame-34-1"><div className="git s2bold">React Native</div></div>
              </div>
            </div>
          </div>
          <div className="frame-343-2 frame-343-3">
            <div className="frame-343">
              <img className="image-9" src="img/image-9-2@2x.png" alt="image 9" />
              <div className="frame-34">
                <h5 className="django-1 django-3 h5">[포카마켓] 백엔드 Django 개발자 (1년 이상)</h5>
                <div className="text-77 s1normal">인플루디오</div>
              </div>
            </div>
            <div className="frame-3439">
              <p className="text-1 s1normal">서울 마포구 ∙ 경력 1-3년 ∙ 상시</p>
              <div className="frame-3437">
                <div className="frame-34-1"><div className="git s2bold">Git</div></div>
                <div className="frame-34-1"><div className="git django-3 s2bold">Django</div></div>
                <div className="frame-34-1"><div className="git s2bold">Python</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;