import React, { useState, useEffect } from 'react';
import { Link, useParams, Route, Routes } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const SolvedProblems = () => {
  const memberId = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const [solvedProblems, setSolvedProblems] = useState([]);
  const [solvedProblemCount, setSolvedProblemCount] = useState(0);

  useEffect(() => {
    fetchSolvedProblems();
  }, []);

  const fetchSolvedProblems = async () => {
    try {
      const response = await axios.post(`${apiUrl}/member/mypage/${memberId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = response.data.data;
      setSolvedProblems(data.solvedProblems); //해결 문제 목록
      setSolvedProblemCount(data.solvedProblemCount); //해결 문제 갯수
    } catch (error) {
      console.error('해결한 문제 목록을 가져오는데 실패했습니다 :', error);
    }
  };

  return (
    <div className="mypage-solved">
      <h1>내가 해결한 문제</h1>
      <p>지금까지 {solvedProblemCount}문제를 해결했습니다</p>
      <ul>
        {solvedProblems.map(problem => (
          <li key={problem.id}>
            <Link to={`/problem/solving/${problem.id}`}>
              {problem.title} - 레벨: {problem.level}, 알고리즘: {problem.category}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SolvedProblems;
