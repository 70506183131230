import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import Home from './components/Home';
import LoginPage from './components/login/LoginPage';
import Logout from './components/login/Logout';
import KakaoCallback from './components/login/KakaoCallback';
import GoogleCallback from './components/login/GoogleCallback';
import MyPage from './components/mypage/MyPage';
import ProblemList from './components/problem/ProblemList';
import ProblemSolving from './components/problem/ProblemSolving';
import Layout from './components/Layout';
import Footer from './components/Footer';

import "./App.css";

const apiUrl = process.env.REACT_APP_API_URL;

function App() {

  const [isTokenValid, setIsTokenValid] = useState(true);
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  // JWT 토큰 유효성 검증 함수
  const validateToken = async () => {
    if (isLoggedIn) {
      const token = localStorage.getItem("token");
      const memberId = localStorage.getItem("id");
      try {
        const response = await axios.get(`${apiUrl}/member/validate-token/${memberId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        return response.data.data;
      } catch (error) {
        console.error('Error fetching problem:', error);
        throw error; // 오류를 상위로 전파
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    // 초기 검증
    validateToken().then(setIsTokenValid);

    // 1분마다 검증
    const intervalId = setInterval(() => {
      validateToken().then(setIsTokenValid);
    }, 60000); // 60000 밀리초 = 1분

    // 컴포넌트가 언마운트될 때 인터벌 정리
    return () => clearInterval(intervalId);
  }, []);

  // 인증 관리 미들웨어
  const PrivateRoute = ({ element }) => {
    if (isLoggedIn && !isTokenValid) {
      alert("로그인 토큰이 유효하지 않습니다. 로그아웃 됩니다.");

      // 토큰이 유효하지 않으면 로그아웃 처리
      localStorage.removeItem('email');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('id');
      localStorage.removeItem('isLoggedIn');

      return <Navigate to="/" />;
    }
    return element;
  };

  return (
    <Router>
      <Routes>
        {/* Login 및 Callback 라우트는 Layout 외부에 배치 */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/kakao/callback" element={<KakaoCallback />} />
        <Route path="/google/callback" element={<GoogleCallback />} />

        {/* 나머지 라우트는 Layout 내부에 배치 */}
        <Route path="/" element={<Layout />}>
          <Route index element={<PrivateRoute element={
            <>
              <Home />
              <Footer />
            </>
          } />} />
          <Route path="/mypage/*" element={<PrivateRoute element={<MyPage />} />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/problem/list" element={<PrivateRoute element={<ProblemList />} />} />
          <Route path="/problem/solving/:problemId" element={<PrivateRoute element={<ProblemSolving />} />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;