import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import UserInfo from './UserInfo';
import SolvedProblems from './SolvedProblems';
import JobMatching from './JobMatching';

function MyPage() {
  return (
    <div className="my-page">
      <div className="navigation">
        <Link to="/" className="back-button">←돌아가기</Link>
      </div>
      <nav className="sidebar">
        <ul>
          <li><Link to="/mypage/user-info">회원정보</Link></li>
          <li><Link to="/mypage/solved-problems">내가 해결한 문제</Link></li>
          <li><Link to="/mypage/feedback-note">오답 노트</Link></li>
          <li><Link to="/mypage/job-matching">채용 매칭</Link></li>
        </ul>
      </nav>
      <div className="content">
        <Routes>
          <Route path="user-info" element={<UserInfo />} />
          <Route path="solved-problems" element={<SolvedProblems />} />
          <Route path="job-matching" element={<JobMatching />} />
          <Route index element={<UserInfo />} /> {/* 기본 페이지 설정 */}
        </Routes>
      </div>
    </div>
  );
}

export default MyPage;