import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

function UserInfo() {
  const [userInfo, setUserInfo] = useState({ email: '', nickname: '' });
  const [newNickname, setNewNickname] = useState('');
  const token = localStorage.getItem("token"); // 예: localStorage에서 가져오기
  const memberId = localStorage.getItem("id"); // 예: 상태나 props에서 가져오기

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.post(`${apiUrl}/member/get-member-info/${memberId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUserInfo(response.data.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const handleNicknameChange = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/member/set-nickname/${memberId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          nickname: encodeURIComponent(newNickname)
        }        
      });
      setUserInfo({ ...userInfo, nickname: newNickname });
      setNewNickname('');
    } catch (error) {
      console.error('Error updating nickname:', error);
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('탈퇴 후 30일 간 회원탈퇴 철회가 가능합니다. 정말로 회원탈퇴를 하시겠습니까?')) {
      try {
        const response = await axios.delete(`${apiUrl}/member/${memberId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status === 204) { // NO_CONTENT 상태 코드 확인
          alert('회원 탈퇴가 완료되었습니다.');
          
          // 로그아웃 처리
          localStorage.removeItem('email');
          localStorage.removeItem('token');
          localStorage.removeItem('role');
          localStorage.removeItem('id');
          localStorage.removeItem('isLoggedIn');
          
          // 홈페이지로 리다이렉트
          window.location.href = '/'; // 또는 React Router를 사용한다면 history.push('/');
        }
  
        // 로그아웃 처리 및 홈페이지로 리다이렉트
        // 예: localStorage.removeItem('token');
        // 예: history.push('/');
      } catch (error) {
        console.error('Error deleting account:', error);
      }
    }
  };

  return (
    <div className="user-info">
      <h2>회원정보</h2>
      <p>이메일: {userInfo.email}</p>
      <p>닉네임: {decodeURIComponent(userInfo.nickname)}</p>
      <form onSubmit={handleNicknameChange}>
        <input
          type="text"
          value={newNickname}
          onChange={(e) => setNewNickname(e.target.value)}
          placeholder="새 닉네임"
        />
        <button type="submit">닉네임 변경</button>
      </form>
      <button onClick={handleDeleteAccount} className="delete-account">회원탈퇴</button>
    </div>
  );
}

export default UserInfo;